import './App.css';
import UPIPaymentComponent from './Sample';


function App() {
  return (
    <div className="App">
      <div className="App-header">
        <text>Google pay Integration</text>
      </div>
      <UPIPaymentComponent />
    </div>
    
    
  );
}

export default App;