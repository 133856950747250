import React, { useEffect } from 'react';

const UPIPaymentComponent = () => {
 
    const canMakePaymentCache = 'canMakePaymentCache';

    function checkCanMakePayment(request) {
      if (sessionStorage.hasOwnProperty(canMakePaymentCache)) {
        return Promise.resolve(JSON.parse(sessionStorage[canMakePaymentCache]));
      }

      var canMakePaymentPromise = Promise.resolve(true);

      if (request.canMakePayment) {
        canMakePaymentPromise = request.canMakePayment();
      }

      return canMakePaymentPromise
        .then((result) => {
          sessionStorage[canMakePaymentCache] = result;
          return result;
        })
        .catch((err) => {
          console.log('Error calling canMakePayment: ' + err);
        });
    }

    function printData(data) {
      alert(data);
    }

    function onBuyClicked() {
      if (!window.PaymentRequest) {
        printData('Web payments are not supported in this browser.');
        return;
      }

      const supportedInstruments = [
        {
          supportedMethods: ['https://tez.google.com/pay'],
          data: {
            pa: '0794374A0068828.bqr@kotak',
            pn: 'test',
            tr: '1234ABCD',
            url: 'https://url/of/the/order/in/your/website',
            mc: '5331',
            tn: 'Purchase in Merchant',
          },
        },
      ];

      const details = {
        total: {
          label: 'Total',
          amount: {
            currency: 'INR',
            value: '1.00',
          },
        },
        displayItems: [
          {
            label: 'Original Amount',
            amount: {
              currency: 'INR',
              value: '1.00',
            },
          },
        ],
      };

      let request = null;
      try {
        request = new PaymentRequest(supportedInstruments, details);
      } catch (e) {
        printData('Payment Request Error: ' + e.message);
        console.log('Payment Request Error: ' + e.message);
        return;
      }
      if (!request) {
        console.log('Web payments are not supported in this browser.');
        return;
      }

      var canMakePaymentPromise = checkCanMakePayment(request);
      canMakePaymentPromise
        .then((result) => {
          showPaymentUI(request, result);
        })
        .catch((err) => {
          console.log('Error calling checkCanMakePayment: ' + err);
        });
    }

    function showPaymentUI(request, canMakePayment) {
      if (!canMakePayment) {
        handleNotReadyToPay();
        return;
      }

      let paymentTimeout = window.setTimeout(function () {
        window.clearTimeout(paymentTimeout);
        request
          .abort()
          .then(function () {
            printData('Payment timed out after 20 minutes.');
            console.log('Payment timed out after 20 minutes.');
          })
          .catch(function () {
            printData("Unable to abort, user is in the process of paying.");
            console.log('Unable to abort, user is in the process of paying.');
          });
      }, 20 * 60 * 1000); 

      request
        .show()
        .then(function (instrument) {
          window.clearTimeout(paymentTimeout);
          completePayment(instrument);
        })
        .catch(function (err) {
          console.log(err);
        });
    }

    function handleNotReadyToPay() {
      alert('Google Pay is not ready to pay.');
    }

    // function processResponse(instrument) {
    //   var instrumentString = paymentResponseToJsonString(instrument);
    //   console.log(instrumentString);
    
    //   fetch('/buy', {
    //     method: 'POST',
    //     headers: new Headers({ 'Content-Type': 'application/json' }),
    //     body: instrumentString,
    //   })
    //     .then(function (buyResult) {
    //       if (!buyResult.ok) {
    //         throw new Error('Error sending instrument to server.');
    //       }
    //       return buyResult.json();
    //     })
    //     .then(function (buyResultJson) {
    //       completePayment(instrument, buyResultJson.status, buyResultJson.message);
    //     })
    //     .catch(function (err) {
    //       printData('Unable to process payment. ' + err.message);
    //       console.log('Unable to process payment. ' + err.message);
    //     });
    // }

    // function completePayment(instrument, result, msg) {
    //   instrument
    //     .complete(result)
    //     .then(function () {
    //       if (result === 'success') {
    //         // Success alert
    //         alert('Payment succeeds: ' + msg);
    //         console.log('Payment succeeds.');
    //         console.log(msg);
    //       } else {
    //         alert('Payment failed: ' + msg);
    //         console.log('Payment failed.');
    //         console.log(msg);
    //       }
    //     })
    //     .catch(function (err) {
    //       // Error alert
    //       alert('Error completing payment: ' + err.message);
    //       console.log(err);
    //       printData(err);
    //     });
    // }


    function completePayment(instrument, msg) {
      instrument
        .complete('success') 
        .then(function () {
          
          alert('Payment succeeds: ' + msg);
          console.log('Payment succeeds.');
          console.log(msg);
        })
        .catch(function (err) {
          
          alert('Error completing payment: ' + err.message);
          console.log(err);
          printData(err);
        });
    }
    

    function paymentResponseToJsonString(paymentResponse) {
      var paymentResponseDictionary = {
        methodName: paymentResponse.methodName,
        details: paymentResponse.details,
        shippingOption: paymentResponse.shippingOption,
        payerName: paymentResponse.payerName,
        payerPhone: paymentResponse.payerPhone,
        payerEmail: paymentResponse.payerEmail,
      };
      return JSON.stringify(paymentResponseDictionary, undefined, 2);
    }
 useEffect(() => {
    // onBuyClicked();

    return () => {
      // Cleanup logic
    };
  }, []);

  return (
    <div>
      <h1>fdgfdhgfh</h1>
      <h1>fdgfdhgfh</h1>
      <button onClick={onBuyClicked}>Buy with Google Pay</button>
    </div>
  );
};

export default UPIPaymentComponent;


